// Dependencies
import Moment from "moment";
import { Link } from "react-router-dom";
// Components
import ModalPostulacion from "./ModalPostulacion";

const OfertaComponent = (props) => {
  const { ofert } = props;

  return (
    <>
      <div className="bg-gray-100 pt-24 pb-4 flex flex-col items-center gap-2">
        <div className="bg-white p-7 w-11/12 md:w-3/4 rounded-xl shadow-xl">
          <div className="flex items-center mb-4">
            <img
              src={ofert._enterprise && ofert._enterprise.logo}
              alt="Coca Cola"
              className="h-12 md:h-16"
            />
            <div className="flex flex-col">
              <Link
                to={`/enterprise/${
                  ofert._enterprise && ofert._enterprise.slug
                }`}
                className="font-bold text-md md:text-lg"
              >
                {ofert._enterprise && ofert._enterprise.name}
              </Link>
              <p className="text-sm md:text-md">
                {Moment(ofert.created).format("DD [de] MMMM [de] YYYY")}
              </p>
            </div>
          </div>
          <h2 className="font-bold text-3xl sm:text-5xl md:text-6xl">
            {ofert.jobTitle}
          </h2>
          <p className="my-2 text-sm md:text-lg">
            México({ofert.modality}) | Semi Semior | {ofert.jobType} |{" "}
            {ofert.sector}
          </p>
          <div className="py-4 flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <box-icon name="user"></box-icon> <p>10 postulaciones</p>
            </div>
            <div className="flex items-center gap-2">
              <box-icon name="time-five"></box-icon>
              <p>Responde entre 1 y 3 días</p>
            </div>
            <div className="flex items-center gap-2">
              <box-icon name="search"></box-icon>
              <p>Revisado por última vez hoy</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6 items-center  mt-5">
            <button className="w-full md:w-auto text-white bg-blue-700 p-4 rounded-lg text-xl">
              Postula a este empleo
            </button>
            <p>Requiere postulación en español</p>
          </div>
        </div>
        <div className="bg-white p-10 md:p-16 w-11/12 md:w-3/4 rounded-xl shadow-xl">
          <p className="text-lg whitespace-pre-wrap">{ofert.description}</p>
          <div className="my-7">
            <p className="text-3xl font-bold">Funciones del Cargo</p>
            <p className="mt-3 text-lg whitespace-pre-wrap">
              {ofert.jobFunctions}
            </p>
          </div>
          <div className="my-7">
            <p className="text-3xl font-bold">Requerimientos del cargo</p>
            <p className="mt-3 text-lg whitespace-pre-wrap">
              {ofert.jobRequirements}
            </p>
          </div>
          <div className="my-7">
            <p className="text-3xl font-bold">
              Requerimientos del cargo (Deseables)
            </p>
            <p className="mt-3 text-lg whitespace-pre-wrap">
              {ofert.desirableJobRequirements}
            </p>
          </div>
        </div>
        <div className="bg-white p-7 w-11/12 md:w-3/4 rounded-xl shadow-xl flex justify-center">
          <div className="w-full sm:w-3/4 flex flex-col md:flex-row flex-wrap">
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon name="wifi" size="md" color="#1D4ED8"></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Teletrabajo opcional
                </p>
                <p>Algunos días puedes trabajarlos desde tu casa.</p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon name="time-five" size="md" color="#1D4ED8"></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Horario flexible
                </p>
                <p>
                  Entrada y salida flexibles, libertad para realizar trámites
                  personales o familiares.
                </p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon
                  name="t-shirt"
                  size="md"
                  color="#1D4ED8"
                  type="solid"
                ></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Vestimenta informal
                </p>
                <p>Coca Cola inc no exige ningún código de vestimenta.</p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon
                  name="shield-alt-2"
                  size="md"
                  color="#1D4ED8"
                ></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Cobertura de salud
                </p>
                <p>
                  Coca Cola inc. paga o copaga cobertura de salud adicional a lo
                  legal
                </p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon name="laptop" size="md" color="#1D4ED8"></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">Computadora</p>
                <p>
                  Coca Cola inc. proporciona una computadora para tu trabajo.
                </p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon name="wrench" size="md" color="#1D4ED8"></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Reparaciones para tu computadora
                </p>
                <p>
                  Coca Cola inc. cubre algunos gastos de reparaciones en caso de
                  desperfectos.
                </p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon
                  name="chalkboard"
                  size="md"
                  color="#1D4ED8"
                ></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Bono de educación
                </p>
                <p>
                  Coca Cola inc. cubre algunos gastos de educación relacionados
                  con el puesto.
                </p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon name="basket" size="md" color="#1D4ED8"></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Descuentos en compras
                </p>
                <p>
                  Falabella Financiero tiene convenios de descuentos con algunos
                  comercios.
                </p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon name="cake" size="md" color="#1D4ED8"></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Día de cumpleaños libre
                </p>
                <p>
                  El día de tu cumpleaños es canjeable por un día completo de
                  vacaciones.
                </p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon name="drink" size="md" color="#1D4ED8"></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Bebidas y snacks
                </p>
                <p>
                  Falabella Financiero ofrece algunas bebidas y snacks de libre
                  disposición en la oficina.
                </p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon name="football" size="md" color="#1D4ED8"></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Áreas recreativas
                </p>
                <p>Espacio para juegos o deportes.</p>
              </div>
            </div>
            <div className="flex w-full md:w-2/4 py-2">
              <div className="w-2/12 flex justify-center">
                <box-icon name="map-pin" size="md" color="#1D4ED8"></box-icon>
              </div>
              <div className="flex flex-col w-10/12">
                <p className="font-bold text-xl text-blue-700">
                  Ayuda con la reubicación
                </p>
                <p>
                  Si vives en otro país, Coca Cola Inc te ayuda con la mudanza y
                  trámites de visa de trabajo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPostulacion />
    </>
  );
};

export default OfertaComponent;
