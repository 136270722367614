// Assets
import imagen from "../../assets/images/PageNotFounded.svg"

const PageNotFounded = () => {
    return (
        <div className="w-sreen h-screen bg-gray-100 flex flex-col justify-center items-center gap-4">
            <img src={imagen} alt="pagina no encontrada" className="w-96" />
            <p className="text-7xl">Oooops!</p>
            <p className="text-2xl">Parece que la página que estas buscando no existe.</p>
        </div>
    )
}

export default PageNotFounded;