// Dependencies
import { Component } from "react";
import swal from "sweetalert";
// Services
import EnterprisesServices from "../../services/Enterprises";
// Components
import EnterpriseComponent from "../../components/Enterprise";

const enterprisesServices = new EnterprisesServices();

class Enterprise extends Component {
  constructor(props) {
    super(props);
    const slug = props.match.params.slug;
    this.state = {
      slug,
      enterprise: {},
      oferts: [],
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getEnterprise();
    this.getOferts();
  };

  getEnterprise = () => {
    enterprisesServices
      .getEnterprise(this.state.slug)
      .then((res) => {
        this.setState({ enterprise: res.results });
      })
      .catch((err) => {
        swal("Error!", err.message, "error");
      });
  };

  getOferts = () => {
    enterprisesServices
      .getOferts(this.state.slug)
      .then((res) => {
        this.setState({ oferts: res.results });
      })
      .catch((err) => {
        swal("Error!", err.message, "error");
      });
  };

  render() {
    return (
      <EnterpriseComponent
        enterprise={this.state.enterprise}
        oferts={this.state.oferts}
      />
    );
  }
}

export default Enterprise;
