// Dependencies
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import swal from "sweetalert";
// Assets
import userImage from "../../../assets/images/usuario.png";

const schema = yup
  .object({
    name: yup.string().required("Campo Obligatorio").trim(),
    last_name: yup.string().required("Campo Obligatorio").trim(),
    email: yup
      .string()
      .required("Campo Obligatorio")
      .trim()
      .email("Correo no valido"),
    title: yup.string().required("Campo Obligatorio").trim(),
    location: yup.string().required("Campo Obligatorio").trim(),
    description: yup.string().required("Campo Obligatorio").trim(),
  })
  .required();

const EditarPerfil = (props) => {
  const [imagenPerfil, setImagenPerfil] = useState(userImage);
  const [file, setFile]= useState(null);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(()=>{
    if(props.datosUsuario){
      const {name, last_name, email, title, location, description} = props.datosUsuario;
      setValue('name', name);
      setValue('last_name', last_name);
      setValue('email', email);
      setValue('title', title);
      setValue('location', location);
      setValue('description', description);
    };
  },[props.datosUsuario])

  const cambiarImagen = (e) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    let valido =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg";
    if (valido) {
      setImagenPerfil(URL.createObjectURL(file));
      setFile(file)
    } else {
      swal(
        "Archivo no valido",
        "Por favor ingresa una un formato en jpg, jpge, png o pdf",
        "warning"
      );
    }
  };

  const edit = (datos) => {
    datos.image = file
    props.updateUser(datos);
  };

  return (
    <div className="bg-gray-100 pt-20 flex justify-center">
      <div className="bg-white rounded-xl shadow-xl p-7 w-3/4 my-5">
        <p className="text-center text-2xl font-title">Editar Perfil</p>
        <form
          className="flex gap-3 my-4 flex-col"
          onSubmit={handleSubmit(edit)}
        >
          <div className="flex flex-col items-center my-3">
            <img
              src={imagenPerfil}
              alt="Usuario"
              className="w-44 h-44 rounded-full shadow-lg"
            />
            <label
              htmlFor="changeImage"
              className="py-2 px-4 text-white bg-blue-700 rounded-md mt-3 cursor-pointer transform hover:scale-105 hover:shadow-xl"
            >
              Seleccionar foto
            </label>
            <Controller
              control={control}
              name="image"
              render={(name) => (
                <input
                type="file"
                hidden
                id="changeImage"
                accept="image/x-png,image/jpg,image/jpeg"
                name={name}
                onChange={(e) => cambiarImagen(e)}
              />
              )}
            />
          </div>
          <div className="flex gap-3">
            <div className="w-2/4">
              <label
                className={`block text-gray-700 text-sm font-bold mb-2 ${
                  errors.name && "text-red-500"
                }`}
                htmlFor="name"
              >
                Nombre *
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.name && "border-red-500"
                }`}
                id="name"
                type="text"
                {...register("name")}
              />
              {errors.name && (
                <p className="text-sm text-red-500 p-1">
                  {errors.name.message}
                </p>
              )}
            </div>
            <div className="w-2/4">
              <label
                className={`block text-gray-700 text-sm font-bold mb-2 ${
                  errors.last_name && "text-red-500"
                }`}
                htmlFor="last_name"
              >
                Apellidos *
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.last_name && "border-red-500"
                }`}
                id="last_name"
                type="text"
                {...register("last_name")}
              />
              {errors.last_name && (
                <p className="text-sm text-red-500 p-1">
                  {errors.last_name.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex gap-3">
            <div className="w-2/4">
              <label
                className={`block text-gray-700 text-sm font-bold mb-2 ${
                  errors.email && "text-red-500"
                }`}
                htmlFor="email"
              >
                Correo Electronico *
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.email && "border-red-500"
                }`}
                id="email"
                type="text"
                {...register("email")}
              />
              {errors.email && (
                <p className="text-sm text-red-500 p-1">
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className="w-2/4">
              <label
                className={`block text-gray-700 text-sm font-bold mb-2 ${
                  errors.title && "text-red-500"
                }`}
                htmlFor="title"
              >
                Titulo *
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.title && "border-red-500"
                }`}
                id="title"
                type="text"
                {...register("title")}
              />
              {errors.title && (
                <p className="text-sm text-red-500 p-1">
                  {errors.title.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex gap-3">
            <div className="w-2/4">
              <label
                className={`block text-gray-700 text-sm font-bold mb-2 ${
                  errors.location && "text-red-500"
                }`}
                htmlFor="location"
              >
                Ubicacion *
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.location && "border-red-500"
                }`}
                id="location"
                type="text"
                {...register("location")}
              />
              {errors.location && (
                <p className="text-sm text-red-500 p-1">
                  {errors.location.message}
                </p>
              )}
            </div>
          </div>
          <div className="w-full">
            <label
              className={`block text-gray-700 text-sm font-bold mb-2 ${
                errors.description && "text-red-500"
              }`}
              htmlFor="description"
            >
              Descripcion *
            </label>
            <textarea
              className={` resize-none shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.description && "border-red-500"
              }`}
              rows="10"
              id="description"
              type="text"
              {...register("description")}
            />
            {errors.description && (
              <p className="text-sm text-red-500 p-1">
                {errors.description.message}
              </p>
            )}
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-700 text-white py-2 px-4 shadow rounded-lg text-xl transform hover:scale-105 hover:shadow-xl"
            >
              Editar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarPerfil;
