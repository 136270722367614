// Dependencies
import { Component } from "react";
// Components
import Child from "../../components/Busqueda";

class Busqueda extends Component {

    render(){
        return (
            <Child/>
        )
    }

}

export default Busqueda;