// Dependencies
import { Component } from "react";
import swal from "sweetalert";
// Componets
import MyEnterprisesComponent from "../../components/MyEnterprises";
// Services
import UserServices from "../../services/Users";

class MyEnterprises extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enterprises: [],
    };
  }

  componentDidMount = () => {
    this.getEnterprises();
  };

  getEnterprises = () => {
    new UserServices()
      .myEnterprises()
      .then((res) => {
        this.setState({ enterprises: res.results });
      })
      .catch((err) => {
        swal("Error!", err.message, "error");
      });
  };

  render() {
    return <MyEnterprisesComponent enterprises={this.state.enterprises}/>;
  }
}

export default MyEnterprises;
