// Dependencies
import Axios from "axios";

const base_url = process.env.REACT_APP_SERVER_URL;

class AuthServices {
  login = (usuario) =>
    new Promise((resolve, reject) => {
      Axios.post(`${base_url}/auth`, usuario)
        .then(async (res) => {
          await localStorage.setItem("token", res.data.jwt);
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  verify = () =>
    new Promise(async (resolve, reject) => {
      const token = await localStorage.getItem("token");
      if (!token) return reject("No se encontro el jwt");
      Axios.post(
        `${base_url}/auth/verify`,
        { token: token },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });
}

export default AuthServices;
