// Components
import Experencia from "./Experience";

const ExperienceList = (props) => {
  return (
    <div className="flex flex-col gap-4 mt-3">
      {props.experiences &&
        props.experiences
          .reverse()
          .map((experience) => (
            <Experencia
              key={experience._id}
              datos={experience}
              removeExperience={props.removeExperience}
            />
          ))}
    </div>
  );
};

export default ExperienceList;
