// Dependencies
import { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
// Actions
import * as authActions from "../../actions/authActions";
// Services
import EnterprisesServices from "../../services/Enterprises";
// Components
import Child from "../../components/Enterprise/Registration";

class EnterpriseRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldTitle: document.title,
    };
  }

  componentDidMount = () => {
    document.title = "Registro de empresa - Jobber";
  };

  addEnterprise = (enterprise) => {
    new EnterprisesServices()
      .create(enterprise)
      .then(async (res) => {
        await this.props.setLogin();
        swal("Exito!", "Empresa registrada", "success").then(() => {
          const slug = res.results.slug;
          this.props.history.push(`/enterprise/${slug}`);
        });
      })
      .catch((err) => {
        swal("Error!", err.message || err, "error");
      });
  };

  componentWillUnmount = () => {
    document.title = this.state.oldTitle;
  };

  render() {
    return <Child addEnterprise={this.addEnterprise} />;
  }
}

const mapStateToProps = ({ authReducer }) => {
  return {
    authReducer,
  };
};

export default connect(mapStateToProps, authActions)(EnterpriseRegistration);
