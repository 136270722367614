// Dependencies
import { useState } from "react";
import ReactMapGL, { Marker } from "react-map-gl";

const Busqueda = () => {
  const accessToken =
    "pk.eyJ1IjoiZWRzZW4iLCJhIjoiY2t2ZTFzNjJnYmJ5czMxbno0NDY1M29jaCJ9.Sta4Mdt-S9Fb2kRrkchRZQ";
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: 19.24534121109508,
    longitude: -103.72278805808492,
    zoom: 11,
  });

  return (
    <div className="bg-gray-100 pt-20 flex justify-center">
      <div className="w-3/4 my-4 flex flex-col gap-2">
        <div className="w-full flex gap-2">
          <div className="w-4/12 bg-white rounded-xl shadow-xl p-7 flex flex-col gap-2">
            <div className="">
              <p className="font-bold">Frontend Developer</p>
              <p className="text-blue-700 text-sm">Tango</p>
              <p className="text-sm">Colima, Mexico (Remoto)</p>
              <p>$18,000 - $25,000</p>
            </div>
            <div className="">
              <p className="font-bold">ODOO Developer</p>
              <p className="text-blue-700 text-sm">Vauxoo</p>
              <p className="text-sm">Colima, Mexico (Remoto)</p>
              <p>$12,000 - $20,000</p>
            </div>
            <div className="">
              <p className="font-bold">Ruby Developer</p>
              <p className="text-blue-700 text-sm">Michelada.io</p>
              <p className="text-sm">Colima, Mexico (Hibrido)</p>
              <p>$40,000 - $70,000</p>
            </div>
            <div className="">
              <p className="font-bold">JavaScript Developer</p>
              <p className="text-blue-700 text-sm">ColimaSoft</p>
              <p className="text-sm">Colima, Mexico (Presencial)</p>
              <p>$15,000 - $20,000</p>
            </div>
            <div className="">
              <p className="font-bold">JavaScript Developer</p>
              <p className="text-blue-700 text-sm">Magmalabs</p>
              <p className="text-sm">Colima, Mexico (Remoto)</p>
              <p>$30,000 - $50,000</p>
            </div>
          </div>
          <div className="w-8/12 bg-white rounded-xl shadow-xl relative">
            <ReactMapGL
              {...viewport}
              mapboxApiAccessToken={accessToken}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              className="rounded-xl"
              onViewportChange={setViewport}
            >
              <Marker longitude={-103.71336024448004} latitude={19.264916245519505}>
                <div className="relative">
                  <div className="bg-white shadow-cl rounded-xl p-2 absolute -top-11">
                    Tango
                  </div>
                  <box-icon type="solid" name="map" color="#264faf"></box-icon>
                </div>
              </Marker>
              <Marker
                longitude={-103.71074825707774}
                latitude={19.26527806410441}
              >
                <div className="relative">
                  <div className="bg-white shadow-cl rounded-xl p-2 absolute -top-11">
                    Magmalabs.io
                  </div>
                  <box-icon type="solid" name="map" color="#264faf"></box-icon>
                </div>
              </Marker>
              <Marker
                longitude={-103.71744368119643}
                latitude={19.266679915460717}
              >
                <div className="relative">
                  <div className="bg-white shadow-cl rounded-xl p-2 absolute -top-11">
                    Michelada.io
                  </div>
                  <box-icon type="solid" name="map" color="#264faf"></box-icon>
                </div>
              </Marker>
              <Marker
                longitude={-103.70793743941483}
                latitude={19.28102813952538}
              >
                <div className="relative">
                  <div className="bg-white shadow-cl rounded-xl p-2 absolute -top-11">
                    ColimaSoft
                  </div>
                  <box-icon type="solid" name="map" color="#264faf"></box-icon>
                </div>
              </Marker>
              <Marker
                longitude={-103.71503505834065}
                latitude={19.273321667331068}
              >
                <div className="relative">
                  <div className="bg-white shadow-cl rounded-xl p-2 absolute -top-11">
                    Vauxoo
                  </div>
                  <box-icon type="solid" name="map" color="#264faf"></box-icon>
                </div>
              </Marker>
            </ReactMapGL>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Busqueda;
