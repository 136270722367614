//& Dependencies
import React from "react";
import ReactDOM from "react-dom";

const Modal = (props) => {
  if (!props.isOpen) {
    document.body.style.overflow = "auto";
    return null;
  } else {
    document.body.style.overflow = "hidden";
  }

  return ReactDOM.createPortal(
    <div className="fixed w-screen h-screen top-0 left-0 flex justify-center items-center z-50 bg-black bg-opacity-75">
      <div className="bg-white shadow-xl rounded-xl w-11/12 md:w-3/4 p-7 relative h-5/6 md:h-auto overflow-y-auto">
        <button onClick={props.onClose} className="absolute right-4 top-2">
          <box-icon name="x" size="md"></box-icon>
        </button>
        {props.children}
      </div>
    </div>,
    document.getElementById("modal")
  );
};

export default Modal;
