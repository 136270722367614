// Dependencies
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
// Actions
import * as authActions from "../../actions/authActions";
// Assets
import userImage from "../../assets/images/usuario.png";
import styles from "../../styles/Global/Menu.module.scss";

const Menu = (props) => {
  const history = useHistory();

  const logout = () => {
    props.logout();
    history.push("/signin");
  };

  return (
    <div className="w-full flex p-5 items-center bg-blue-800 text-white absolute">
      <div className="w-3/12">
        <Link to="/" className="text-2xl no-underline text-white">
          Jobber
        </Link>
      </div>
      <div className="w-6/12">
        <ul className="flex gap-5 w-full justify-center">
          {props.authReducer.login && (
            <li className="py-1 border-b border-white cursor-pointer">
              <Link to="/dashboard" className="no-underline text-white">
                Dashboard
              </Link>
            </li>
          )}
          <li className="py-1 border-b border-white cursor-pointer">
            <Link to="/enterprises">
              Empresas
            </Link>
          </li>
          <li className="py-1 border-b border-white cursor-pointer">
            Vacantes
          </li>
        </ul>
      </div>

      {props.authReducer.login ? (
        <div className={`w-3/12 flex justify-end z-10 relative ${styles.menu}`}>
          <img
            src={props.authReducer.user.image || userImage}
            alt={props.authReducer.user.name}
            className="w-10 rounded-full"
          />
          <div className={`bg-white rounded-xl shadow-xl p-3 text-black absolute top-full ${styles.submenu}`}>
            <ul className="flex flex-col gap-2">
              <li>
                <Link to="/perfil">Perfil</Link>
              </li>
              <li>
                <Link to="/enterprise/new">Registrar una empresa</Link>
              </li>
              {props.authReducer.login &&
                props.authReducer.user.haveEnterprise && (
                  <li>
                    <Link to="/my-enterprises">Mis Empresas</Link>
                  </li>
                )}
              <li onClick={logout} className="cursor-pointer">
                Cerrar Sesion
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="w-3/12 flex justify-end">
          <Link to={"/signin"}>
            {" "}
            <p>Iniciar Sesion</p>
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    authReducer,
  };
};

export default connect(mapStateToProps, authActions)(Menu);
