// Dependencies
import { Component } from "react";
import swal from "sweetalert";
// Components
import EnterpriseListComponent from "../../components/Enterprise/List";
// Services
import EnterprisesServices from "../../services/Enterprises";

const enterprisesServices = new EnterprisesServices();

class EnterpriseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enterprises: [],
    };
  }

  componentDidMount = () => {
    this.getEnterprises();
  };

  getEnterprises = () => {
    enterprisesServices
      .getAll()
      .then((res) => this.setState({ enterprises: res.results }))
      .catch((err) => {
        swal("Oooops", err.message || err, "error");
      });
  };

  render() {
    return <EnterpriseListComponent enterprises={this.state.enterprises} />;
  }
}

export default EnterpriseList;
