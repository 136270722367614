// Dependencies
import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
// Services
import UserServices from "../../services/Users";
// Components
import Child from "../../components/SignUp";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if (this.props.authReducer.login) {
      return this.props.history.push("/dashboard");
    }
  };

  createUser = (usuario) => {
    new UserServices()
      .createUser(usuario)
      .then((res) => {
        swal("Exito!", "Usuario creado.", "success").then((acction) => {
          this.props.history.push("/signin");
        });
      })
      .catch((err) => {
        swal("Oooops!", err, "error");
      });
  };

  render() {
    return <Child createUser={this.createUser} />;
  }
}

const mapStateToProps = ({ authReducer }) => ({
  authReducer,
});

export default connect(mapStateToProps)(SignUp);
