// Dependencies
import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// Components
import Menu from "../components/Global/Menu";
import Footer from "../components/Global/Footer";
import Perfil from "../pages/Perfil";
import EditarPerfil from "../pages/Perfil/Editar";
import PageNotFounded from "../components/Global/PageNotFounded";
import LandingPage from "../pages/LandingPage";
import LogIn from "../pages/LogIn";
import SignUp from "../pages/SignUp";
import Dashboard from "../pages/Dashboard";
import Busqueda from "../pages/Busqueda";
import About from "../components/About";
import Enterprise from "../pages/Enterprise";
import EnterpriseRegistration from "../pages/Enterprise/Registration";
import MyEnterprises from "../pages/MyEnterprises";
import Oferta from "../pages/Oferta";
import NewOfert from "../pages/Oferta/New";
import EnterpriseList from "../pages/Enterprise/List";

import { connect } from "react-redux";
import * as authActions from "../actions/authActions";

class App extends Component {
  componentDidMount = () => {
    if (!this.props.authReducer.login) {
      this.props.setLogin();
    }
  };

  render() {
    return (
      <BrowserRouter>
        <Menu />
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/signin" component={LogIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/perfil" component={Perfil} />
          <Route exact path="/perfil/editar" component={EditarPerfil} />
          <Route excat path="/busqueda" component={Busqueda} />
          <Route exact path="/about" component={About} />
          <Route
            exact
            path="/enterprise/new"
            component={EnterpriseRegistration}
          />
          <Route exact path="/enterprise/:slug" component={Enterprise} />
          <Route exact path="/oferta/new/:id" component={NewOfert} />
          <Route exact path="/oferta/:id" component={Oferta} />
          <Route exact path="/my-enterprises" component={MyEnterprises} />
          <Route exact path="/enterprises" component={EnterpriseList} />
          <Route exact component={PageNotFounded} />
        </Switch>
        <Footer />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ authReducer }) => ({
  authReducer,
});

export default connect(mapStateToProps, authActions)(App);
