// Dependencies
import { Link } from "react-router-dom";
// Components
import ExperenciaList from "./ExperienceList";
import StudiesList from "./StudiesList";
import CertificadoList from "./CertificadoLIst";
import ConocimientoList from "./ConocimientoList";
import ModalStudies from "./ModalStudies";
import ModalExperience from "./ModalExperience";
import ModalCertificados from "./ModalCertificados";
import ModalConocimientos from "./ModalConocimientos";
// Assets
import user from "../../assets/images/usuario.png";

const PerfilComponent = (props) => {
  const datosUsuario = props.datosUsuario();

  return (
    <>
      <div className="bg-gray-100 pt-20 flex justify-center w-full">
        <div className="w-11/12 lg:w-3/4 py-10 flex gap-2 flex-wrap">
          <div className="w-full bg-white shadow-xl rounded-xl p-7 flex flex-col items-center md:flex-row gap-4">
            <img
              src={datosUsuario.image || user}
              alt={datosUsuario.name}
              className="rounded-full w-44 h-44 mx-2 z-20"
            />
            <div className="mt-3 flex flex-col items-center md:items-start">
              <div className="mb-2 flex items-center gap-7">
                <p className="text-2xl">
                  {datosUsuario.name} {datosUsuario.last_name}
                </p>
                <Link to="/perfil/editar">
                  <box-icon name="pencil"></box-icon>
                </Link>
              </div>
              <p>{datosUsuario.title}</p>
              <p>{datosUsuario.location}</p>
              <div className="flex gap-3 py-2 mt-1">
                <box-icon
                  type="logo"
                  name="facebook-circle"
                  color="#707579"
                ></box-icon>
                <box-icon
                  name="instagram"
                  type="logo"
                  color="#707579"
                ></box-icon>
                <box-icon name="twitter" type="logo" color="#707579"></box-icon>
              </div>
              <p>{datosUsuario.description}</p>
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-2">
            <div className="w-full lg:w-7/12 flex flex-col gap-2">
              <div className="bg-white shadow-xl rounded-xl p-7 gap-4">
                <div className="flex justify-between items-center">
                  <p className="text-xl">Experiencia Laboral</p>
                  <div
                    className="cursor-pointer transformf flex items-center transform hover:scale-125 duration-100"
                    onClick={props.toggleModalExperience}
                  >
                    <box-icon name="plus"></box-icon>
                  </div>
                </div>
                <ExperenciaList
                  experiences={datosUsuario.experience}
                  removeExperience={props.removeExperience}
                />
              </div>
              <div className="bg-white shadow-xl rounded-xl p-7 gap-4">
                <div className="flex justify-between items-center">
                  <p className="text-xl">Estudios</p>
                  <div
                    className="cursor-pointer transformf flex items-center transform hover:scale-125 duration-100"
                    onClick={props.toggleModalStudies}
                  >
                    <box-icon name="plus"></box-icon>
                  </div>
                </div>
                <StudiesList
                  studies={datosUsuario.study}
                  removeStudy={props.removeStudy}
                />
              </div>
            </div>
            <div className="w-full lg:w-5/12 flex flex-col gap-2">
              <div className="bg-white shadow-xl rounded-xl p-7 flex flex-col gap-4">
                <div className="flex justify-between items-center">
                  <p className="text-xl">Conocimientos</p>
                  <div
                    className="cursor-pointer transformf flex items-center transform hover:scale-125 duration-100"
                    onClick={props.toggleModalConocimientos}
                  >
                    <box-icon name="pencil"></box-icon>
                  </div>
                </div>
                <ConocimientoList conocimientos={datosUsuario.conocimiento} />
              </div>
              <div className="bg-white shadow-xl rounded-xl p-7 flex flex-col gap-4">
                <div className="flex justify-between items-center">
                  <p className="text-xl">Certificados</p>
                  <div
                    className="cursor-pointer transformf flex items-center transform hover:scale-125 duration-100"
                    onClick={props.toggleModalCertificados}
                  >
                    <box-icon name="plus"></box-icon>
                  </div>
                </div>
                <CertificadoList
                  certificados={datosUsuario.certificado}
                  removeCertificado={props.removeCertificado}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalExperience
        isOpen={props.isModalExperienceOpen}
        onClose={props.toggleModalExperience}
        addExperience={props.addExperience}
      />
      <ModalStudies
        isOpen={props.isModalStudiesOpen}
        onClose={props.toggleModalStudies}
        addStudy={props.addStudy}
      />
      <ModalCertificados
        isOpen={props.isModalCertificadosOpen}
        onClose={props.toggleModalCertificados}
        addCertificado={props.addCertificado}
      />
      <ModalConocimientos
        isOpen={props.isModalConocimientosOpen}
        onClose={props.toggleModalConocimientos}
        addConocimiento={props.addConocimiento}
        conocimientos={datosUsuario.conocimiento}
        removeConocimiento={props.removeConocimiento}
      />
    </>
  );
};

export default PerfilComponent;
