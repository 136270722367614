// Dependencies
import Conocimiento from "./Conocimiento";

const ConocimientoList = (props) => {
  return (
    <div className="flex gap-3 flex-wrap justify-center">
      {props.conocimientos &&
        props.conocimientos.map((conocimiento) => (
          <Conocimiento key={conocimiento._id} datos={conocimiento} edit={props.edit ? true : false} remove={props.removeConocimiento}/>
        ))}
    </div>
  );
};

export default ConocimientoList;
