// components
import Certificado from "./Certificado";

const CertificadoList = (props) => {
  return (
    <div className="flex flex-col gap-3">
      {props.certificados &&
        props.certificados.map((certificado) => (
          <Certificado
            key={certificado._id}
            datos={certificado}
            removeCertificado={props.removeCertificado}
          />
        ))}
    </div>
  );
};

export default CertificadoList;
