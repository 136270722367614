// Components
import Study from "./Study";

const StudiesList = (props) => {
  return (
    <div className="flex flex-col gap-4 mt-3">
      {props.studies && props.studies.map((study) => <Study datos={study} removeStudy={props.removeStudy}/>)}
    </div>
  );
};

export default StudiesList;
