// Depedencies
import Moment from "moment";

const Experience = (props) => {
  const {
    title,
    enterprise,
    jobType,
    startDate,
    endDate,
    modality,
    location,
    description,
    _id
  } = props.datos;

  return (
    <div className="">
      <div className="flex justify-between">
        <p className="font-bold text-lg">{title}</p>
        <div className="flex gap-3">
          {/* <box-icon name="pencil"></box-icon> */}
          <box-icon name="trash" onClick={()=>props.removeExperience(_id)}></box-icon>
        </div>
      </div>
      <p className="text-blue-600">
        {enterprise} - {jobType}
      </p>
      <p>
        {Moment(startDate).format("MMM YYYY")} -{" "}
        {Moment(endDate).format("MMM YYYY")}
      </p>
      <p>
        {location} ({modality})
      </p>
      <p className="mt-2">{description}</p>
    </div>
  );
};

export default Experience;
