// Dependencies
import Axios from "axios";

const base_url = process.env.REACT_APP_SERVER_URL;

class UserServices {
  createUser = (usuario) =>
    new Promise((resolve, reject) => {
      Axios.post(`${base_url}/users`, usuario)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  updateUser = (id, usuario) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(usuario).forEach((key) => formData.append(key, usuario[key]));
      Axios.put(`${base_url}/users/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  createExperience = (datos) =>
    new Promise((resolve, reject) => {
      Axios.post(`${base_url}/users/experience`, datos, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  deleteExperience = (id) =>
    new Promise((resolve, reject) => {
      Axios.delete(`${base_url}/users/experience`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          id,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  createStudy = (datos) =>
    new Promise((resolve, reject) => {
      Axios.post(`${base_url}/users/study`, datos, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  deleteStudy = (id) =>
    new Promise((resolve, reject) => {
      Axios.delete(`${base_url}/users/study`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          id,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  createCertificado = (datos) =>
    new Promise((resolve, reject) => {
      Axios.post(`${base_url}/users/certificado`, datos, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  deleteCertificado = (id) =>
    new Promise((resolve, reject) => {
      Axios.delete(`${base_url}/users/certificado`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          id,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  createConocimiento = (datos) =>
    new Promise((resolve, reject) => {
      Axios.post(`${base_url}/users/conocimiento`, datos, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  deleteConocimiento = (id) =>
    new Promise((resolve, reject) => {
      Axios.delete(`${base_url}/users/conocimiento`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          id,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  myEnterprises = () =>
    new Promise((resolve, reject) => {
      Axios.get(`${base_url}/users/enterprises`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });
}

export default UserServices;
