// Dependencies
import { Component } from "react";
import swal from "sweetalert";
// Components
import Child from "../../components/Oferta";
// Services
import OfertasServices from "../../services/Ofertas";

const ofertasServices = new OfertasServices();

class Oferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ofert: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getOfert();
  };

  getOfert = () => {
    ofertasServices
      .getOfert(this.props.match.params.id)
      .then((res) => {
        this.setState({ ofert: res.results });
      })
      .catch((err) => {
        swal("Ooooop!", "No se encontro oferta", "error");
      });
  };

  render() {
    return <Child ofert={this.state.ofert} />;
  }
}

export default Oferta;
