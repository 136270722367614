// Dependencies
import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import * as authActions from "../../actions/authActions";
// Services
import AuthServices from "../../services/Auth";
// Components
import Child from "../../components/LogIn";

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  login = (user) => {
    new AuthServices()
      .login(user)
      .then(async(res) => {
        await this.props.setLogin();
        this.props.history.push("/dashboard");
      })
      .catch((err) => {
        swal("Ooooops!", err, "error");
      });
  };

  componentDidMount = () => {
    if (this.props.authReducer.login) {
      this.props.history.push("/dashboard");
    }
  };

  render() {
    return <Child login={this.login} />;
  }
}

const mapStateToProps = ({ authReducer }) => ({
  authReducer,
});

export default connect(mapStateToProps, authActions)(LogIn);
