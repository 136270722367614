// Dependencies
import Moment from "moment";

const Study = (props) => {
  const { title, institution, startDate, endaDate, location, description, _id } =
    props.datos;

  return (
    <div className="">
      <div className="flex justify-between">
        <p className="font-bold text-lg">{title}</p>
        <div className="flex gap-3">
          {/* <box-icon name="pencil"></box-icon> */}
          <box-icon name="trash" onClick={()=>props.removeStudy(_id)}></box-icon>
        </div>
      </div>
      <p className="text-blue-600">{institution}</p>
      <p>
        {Moment(startDate).format('MMM YYYY')} - {Moment(endaDate).format('MMM YYYY')}
      </p>
      <p>{location}</p>
      <p className="mt-2">{description}</p>
    </div>
  );
};

export default Study;
