// Dependencies
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// Assets
import SVG from "../../assets/images/LogIn/login.svg";

const schema = yup
  .object({
    email: yup.string().required("Campo Obligatorio").trim(),
    password: yup.string().required("Campo Obligatorio").trim(),
  })
  .required();

const LogIn = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const login = (e) => {
    props.login(e)
  };

  return (
    <div className="bg-gray-100 w-full flex justify-center items-center h-screen pt-20">
      <div className="w-11/12 lg:w-7/12 flex flex-col md:flex-row bg-white shadow-xl rounded-2xl p-5 lg:p-10 items-center">
        <div className="content_imagen w-5/12">
          <img src={SVG} alt="usuario en una computadora" />
        </div>
        <form className="px-1 md:px-8 pt-6 pb-8 mb-4 w-full md:w-7/12" onSubmit={handleSubmit(login)}>
          <div className="mb-4">
            <label
              className={`block text-gray-700 text-sm font-bold mb-2 ${
                errors.email && "text-red-500"
              }`}
              htmlFor="email"
            >
              Correo Electronico *
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.email && "border-red-500"
              }`}
              id="username"
              type="text"
              {...register("email")}
            />
            {errors.email && (
              <p className="text-sm text-red-500 p-1">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="mb-6">
            <label
              className={`block text-gray-700 text-sm font-bold mb-2 ${
                errors.password && "text-red-500"
              }`}
              htmlFor="password"
            >
              Contraseña *
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.password && "border-red-500"
              }`}
              id="password"
              type="password"
              {...register("password")}
            />
            {errors.password && (
              <p className="text-sm text-red-500 p-1">
                {errors.password.message}
              </p>
            )}
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <button
              className="w-full md:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Sign In
            </button>
            <Link
              className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
              to="/signup"
            >
              ¿No tienes cuenta? Registrate aqui
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LogIn;
