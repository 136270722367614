// Dependencies
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// Componens
import Modal from "../Global/Modal";

const schema = yup
  .object({
    title: yup.string().required("Campo Obligatorio").trim(),
    institution: yup.string().required("Campo Obligatorio").trim(),
    startDate: yup
      .date()
      .required("Campo Obligatorio")
      .typeError("Ingresa una fecha valida"),
    endDate: yup
      .date()
      .required("Campo Obligatorio")
      .typeError("Ingresa una fecha valida"),
    location: yup.string().trim(),
    description: yup.string().trim(),
  })
  .required();

const ModalStudies = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const agregar = (datos) => {
    props.addStudy(datos);
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <p className="text-center text-3xl mb-5">Agregar Estudio</p>
      <form
        onSubmit={handleSubmit(agregar)}
        noValidate
        className="flex flex-col gap-2"
      >
        <div className="flex gap-2">
          <div className="w-2/4">
            <label
              className={`block text-gray-700 text-sm font-bold mb-2 ${
                errors.title && "text-red-500"
              }`}
              htmlFor="title"
            >
              Titulo *
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.title && "border-red-500"
              }`}
              id="title"
              type="text"
              {...register("title")}
            />
            {errors.title && (
              <p className="text-sm text-red-500 p-1">{errors.title.message}</p>
            )}
          </div>
          <div className="w-2/4">
            <label
              className={`block text-gray-700 text-sm font-bold mb-2 ${
                errors.institution && "text-red-500"
              }`}
              htmlFor="institution"
            >
              Institución *
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.institution && "border-red-500"
              }`}
              id="institution"
              type="text"
              {...register("institution")}
            />
            {errors.institution && (
              <p className="text-sm text-red-500 p-1">
                {errors.institution.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex gap-2">
          <div className="w-2/4">
            <label
              className={`block text-gray-700 text-sm font-bold mb-2 ${
                errors.startDate && "text-red-500"
              }`}
              htmlFor="startDate"
            >
              Fecha de Inicio *
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.startDate && "border-red-500"
              }`}
              id="startDate"
              type="date"
              {...register("startDate")}
            />
            {errors.startDate && (
              <p className="text-sm text-red-500 p-1">
                {errors.startDate.message}
              </p>
            )}
          </div>
          <div className="w-2/4">
            <label
              className={`block text-gray-700 text-sm font-bold mb-2 ${
                errors.endDate && "text-red-500"
              }`}
              htmlFor="endDate"
            >
              Fecha Final *
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.endDate && "border-red-500"
              }`}
              id="endDate"
              type="date"
              {...register("endDate")}
            />
            {errors.endDate && (
              <p className="text-sm text-red-500 p-1">
                {errors.endDate.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full">
          <div className="w-2/4">
            <label
              className={`block text-gray-700 text-sm font-bold mb-2 ${
                errors.location && "text-red-500"
              }`}
              htmlFor="location"
            >
              Ubicacion
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.location && "border-red-500"
              }`}
              id="location"
              type="text"
              {...register("location")}
            />
            {errors.location && (
              <p className="text-sm text-red-500 p-1">
                {errors.location.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full">
          <label
            className={`block text-gray-700 text-sm font-bold mb-2 ${
              errors.description && "text-red-500"
            }`}
            htmlFor="description"
          >
            Descripcion
          </label>
          <textarea
            rows="5"
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.description && "border-red-500"
            }`}
            id="description"
            type="text"
            {...register("description")}
          />
          {errors.description && (
            <p className="text-sm text-red-500 p-1">
              {errors.description.message}
            </p>
          )}
        </div>
        <div className="flex justify-center mt-5">
          <button
            type="submit"
            className="bg-blue-700 text-white text-xl px-4 py-2 rounded-xl transform hover:scale-110 duration-300"
          >
            Agregar
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalStudies;
