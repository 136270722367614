// Dependencies
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const EnterpriseListComponent = (props) => {
  const changePage = (e, page) => {
    console.log(page);
  };

  const showPagination = () => (
    <Stack spacing={2} className="w-full flex flex-col items-center mt-10">
      <Pagination
        count={props.enterprises.pages || 10}
        shape="rounded"
        onChange={changePage}
      />
    </Stack>
  );

  return (
    <div className="pt-24 bg-gray-100 flex justify-center items-center pb-4">
      <div className="w-11/12 md:w-3/4 bg-white shadow-xl rounded-xl p-7">
        <h2 className="text-4xl text-center">Empresas</h2>
        <div className="mt-5">
          {props.enterprises.docs &&
            props.enterprises.docs.map((enterprise) => (
              <div key={enterprise._id} className="flex flex-col md:flex-row items-center py-5 shadow-md">
                <img
                  src={enterprise.logo}
                  alt={enterprise.name}
                  className="w-72 md:w-1/4"
                />
                <div className="flex flex-col gap-2 w-3/4">
                  <p className="text-xl font-bold">{enterprise.name}</p>
                  <p>{enterprise.description}</p>
                  <Link
                    to={`/enterprise/${enterprise.slug}`}
                    className="underline text-blue-500"
                  >
                    Ver más...
                  </Link>
                </div>
              </div>
            ))}
        </div>
        {showPagination()}
      </div>
    </div>
  );
};

export default EnterpriseListComponent;
