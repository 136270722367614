// Dependencies
import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// Assets
import SVG from "../../assets/images/LogIn/login.svg";

const schema = yup
  .object({
    name: yup.string().required("Campo Obligatorio").trim(),
    last_name: yup.string().required("Campo Obligatorio").trim(),
    password: yup.string().required("Campo Obligatorio").trim(),
    email: yup
      .string()
      .required("Campo Obligatorio")
      .trim()
      .email("Ingresa un correo valido"),
  })
  .required();

const SignUp = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const login = (userData) => {
    props.createUser(userData);
  };

  return (
    <div className="bg-gray-100 w-full flex justify-center items-center h-screen">
      <div className="w-11-12 md:w-9/12 lg:w-8/12 flex flex-col md:flex-row bg-white shadow-xl rounded-2xl p-5 lg:p-10 items-center pt-24">
        <div className="w-5/12">
          <img src={SVG} alt="usuario en una computadora" />
        </div>
        <div className="px-1 md:px-8 pt-6 pb-8 mb-4 w-full md:w-7/12">
          <form
            className="w-full"
            onSubmit={handleSubmit(login)}
            noValidate
          >
            <div className="flex flex-wrap gap-0 justify-between">
              <div className="mb-4 w-full lg:w-1/2">
                <label
                  className={`block text-gray-700 text-sm font-bold mb-2 ${
                    errors.name && "text-red-500"
                  }`}
                  htmlFor="nombre"
                >
                  Nombre *
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errors.name && "border-red-500"
                  }`}
                  id="nombre"
                  type="text"
                  {...register("name")}
                />
                {errors.name && (
                  <p className="text-sm text-red-500 p-1">
                    {errors.name.message}
                  </p>
                )}
              </div>
              <div className="mb-4 w-full lg:w-1/2">
                <label
                  className={`block text-gray-700 text-sm font-bold mb-2 ${
                    errors.last_name && "text-red-500"
                  }`}
                  htmlFor="last_name"
                >
                  Apellidos *
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    errors.last_name && "border-red-500"
                  }`}
                  id="last_name"
                  type="text"
                  {...register("last_name")}
                />
                {errors.last_name && (
                  <p className="text-sm text-red-500 p-1">
                    {errors.last_name.message}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label
                className={`block text-gray-700 text-sm font-bold mb-2 ${
                  errors.email && "text-red-500"
                }`}
                htmlFor="email"
              >
                Correo Electronico *
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.email && "border-red-500"
                }`}
                id="email"
                type="email"
                {...register("email")}
              />
              {errors.email && (
                <p className="text-sm text-red-500 p-1">
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                className={`block text-gray-700 text-sm font-bold mb-2 ${
                  errors.password && "text-red-500"
                }`}
                htmlFor="password"
              >
                Contraseña *
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.password && "border-red-500"
                }`}
                id="password"
                type="password"
                {...register("password")}
              />
              {errors.password && (
                <p className="text-sm text-red-500 p-1">
                  {errors.password.message}
                </p>
              )}
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-between">
              <button
                className="w-full lg:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Sign Up
              </button>
              <Link
                className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                to="/signin"
              >
                ¿Ya tienes una cuenta? Inicia Sesión
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
