// Components
import Search from "../Global/Search";
// Assets
import Usuario from "../../assets/images/usuario.png";
import Karen from "../../assets/images/karen.jpeg";
import Tairi from "../../assets/images/tairi.jpg";

const DashboardComponent = (props) => {

  const datosUsuario = props.datosUsuario();

  return (
    <div>
      <Search />
      <div className="bg-gray-100 flex justify-center">
        <div className="w-11/12 md:w-3/4 py-10 flex flex-col lg:flex-row gap-2">
          <div className="w-full lg:w-7/12 flex flex-col gap-2">
            <div className="shadow-lg bg-white rounded-lg p-7">
              <p className="text-xl">Tus ultimas solicitudes</p>
              <div className="flex my-3 items-center">
                <div className="w-10/12">
                  <p>ReactJs Developer - Appio</p>
                  <p className="text-sm text-green-600">Hace 1 horas</p>
                </div>
                <div className="w-2/12 flex flex-col items-center">
                  <box-icon name="paper-plane"></box-icon>
                  <p>Postulada</p>
                </div>
              </div>
              <div className="flex my-3 items-center">
                <div className="w-10/12">
                  <p>Ruby Developer - Michelada.io</p>
                  <p className="text-sm text-green-600">Hace 2 Dias</p>
                </div>
                <div className="w-2/12 flex flex-col items-center">
                  <box-icon name="check-double"></box-icon>
                  <p>En revision</p>
                </div>
              </div>
              <div className="flex my-3 items-center">
                <div className="w-10/12">
                  <p>Cajero - Walmart</p>
                  <p className="text-sm text-green-600">Hace 2 Dias</p>
                </div>
                <div className="w-2/12 flex flex-col items-center">
                  <box-icon name="check-double"></box-icon>
                  <p>En revision</p>
                </div>
              </div>
              <div className="flex my-3 items-center">
                <div className="w-10/12">
                  <p>Ventas - La Marina</p>
                  <p className="text-sm text-green-600">Hace 7 Dias</p>
                </div>
                <div className="w-2/12 flex flex-col items-center">
                  <box-icon name="x-circle"></box-icon>
                  <p>Rechazada</p>
                </div>
              </div>
              <div className="flex my-3 items-center">
                <div className="w-10/12">
                  <p>Chef - Altozano</p>
                  <p className="text-sm text-green-600">Hace 7 Dias</p>
                </div>
                <div className="w-2/12 flex flex-col items-center">
                  <box-icon name="x-circle"></box-icon>
                  <p>Rechazada</p>
                </div>
              </div>
              <p className="text-sm">Ver mas...</p>
            </div>
            <div className="shadow-lg bg-white rounded-lg p-7">
              <p className="text-xl">Vacantes recomendadas</p>
              <div className="my-3">
                <p className="text-xl">Jr Developer - 3MIUM</p>
                <p className="text-blue-600">
                  Monterrey, Nuevo León, México (En Remoto)
                </p>
                <p className="text-sm">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Optio necessitatibus quo corporis quis. Est molestiae
                  reprehenderit aut repellendus, minus temporibus accusantium,
                  ipsum soluta commodi dolore possimus fugiat ullam aperiam
                  odit...
                </p>
              </div>
              <div className="my-3">
                <p className="text-xl">Frontend Developer - ACI INFOTECH</p>
                <p className="text-blue-600">Mexico (En Remoto)</p>
                <p className="text-sm">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Optio necessitatibus quo corporis quis. Est molestiae
                  reprehenderit aut repellendus, minus temporibus accusantium,
                  ipsum soluta commodi dolore possimus fugiat ullam aperiam
                  odit...
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-5/12">
            <div className="shadow-lg bg-white rounded-lg p-7 flex flex-col items-center">
              <img src={datosUsuario.image || Usuario} alt="Usuario" className="w-40 rounded-full" />
              <p className="text-2xl mt-2">{ datosUsuario.name} {datosUsuario.last_name}</p>
              <p className="text-sm">{datosUsuario.title}</p>
              <p className="text-sm">{datosUsuario.location}</p>
              <div className="mt-5">
                <p className="text-center mb-2">Etiquetas monitoreadas</p>
                <div className="flex flex-wrap gap-3 p-2 justify-center">
                  <div className="div flex items-center gap-2 bg-gray-100 p-2 rounded-2xl text-sm">
                    <box-icon name="x"></box-icon>
                    Frontend developer
                  </div>
                  <div className="div flex items-center gap-2 bg-gray-100 p-2 rounded-2xl text-sm">
                    <box-icon name="x"></box-icon>
                    Developer
                  </div>
                  <div className="div flex items-center gap-2 bg-gray-100 p-2 rounded-2xl text-sm">
                    <box-icon name="x"></box-icon>
                    Java Developer
                  </div>
                  <div className="div flex items-center gap-2 bg-gray-100 p-2 rounded-2xl text-sm">
                    <box-icon name="x"></box-icon>
                    Remoto
                  </div>
                  <div className="div flex items-center gap-2 bg-gray-100 p-2 rounded-2xl text-sm">
                    <box-icon name="x"></box-icon>
                    Colima, col
                  </div>
                </div>
              </div>
              <div className="mt-5 w-full">
                <p className="text-center mb-3">
                  Personas interesadas en tu perfil
                </p>
                <div className="flex flex-col gap-3">
                  <div className="flex gap-3 bg-gray-100 p-2 rounded-xl">
                    <img
                      src={Karen}
                      alt="Karen Cernas"
                      className="w-14 rounded-full"
                    />
                    <div>
                      <p>Karen Cernas</p>
                      <p className="text-sm">COO - Jobber</p>
                    </div>
                  </div>
                  <div className="flex gap-3 bg-gray-100 p-2 rounded-xl">
                    <img
                      src={Tairi}
                      alt="Karen Cernas"
                      className="w-14 rounded-full"
                    />
                    <div>
                      <p>Tairi Jaimes</p>
                      <p className="text-sm">
                        Frontend Developer - Michelada.io
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardComponent;
