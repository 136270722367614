// Dependencies
import Axios from "axios";

const base_url = process.env.REACT_APP_SERVER_URL;

class OfertasServices {
  addOfert = (datos) =>
    new Promise((resolve, reject) => {
      Axios.post(`${base_url}/ofertas`, datos, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });
  getOfert = (id) =>
    new Promise((resolve, reject) => {
      Axios.get(`${base_url}/ofertas/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });
}

export default OfertasServices;
