// Components
import Search from "../Global/Search";
// Assets
import CocaCola from "../../assets/images/empresas/Coca-Cola.svg";
import SpaceX from "../../assets/images/empresas/SpaceX.svg";
import Netflix from "../../assets/images/empresas/netflix.svg";
import BBVA from "../../assets/images/empresas/BBVA.svg";
import MercadoLibre from "../../assets/images/empresas/MercadoLibre.svg";
import AeroMexico from "../../assets/images/empresas/Aeromexico.svg";
import Apple from "../../assets/images/empresas/Apple.svg";
import Bimbo from "../../assets/images/empresas/Bimbo.svg";
import KFC from "../../assets/images/empresas/KFC.svg";

const LandingPage = () => {
  return (
    <div className="bg-gray-100">
      <Search />
      <div className="px-7 p-4">
        <p className="text-center text-xl">Principales Busquedas</p>
        <div className="flex justify-center gap-5 my-5 flex-wrap">
          <div className="flex gap-1 items-center bg-white rounded-xl p-2 shadow-md cursor-pointer transform hover:scale-110 hover:shadow-xl duration-150">
            <box-icon name="search" className="w-4"></box-icon>
            <p>Frontend Developer</p>
          </div>
          <div className="flex gap-1 items-center bg-white rounded-xl p-2 shadow-md cursor-pointer transform hover:scale-110 hover:shadow-xl duration-150">
            <box-icon name="search" className="w-4"></box-icon>
            <p>React Developer</p>
          </div>
          <div className="flex gap-1 items-center bg-white rounded-xl p-2 shadow-md cursor-pointer transform hover:scale-110 hover:shadow-xl duration-150">
            <box-icon name="search" className="w-4"></box-icon>
            <p>Medio Tiempo</p>
          </div>
          <div className="flex gap-1 items-center bg-white rounded-xl p-2 shadow-md cursor-pointer transform hover:scale-110 hover:shadow-xl duration-150">
            <box-icon name="search" className="w-4"></box-icon>
            <p>Remoto</p>
          </div>
          <div className="flex gap-1 items-center bg-white rounded-xl p-2 shadow-md cursor-pointer transform hover:scale-110 hover:shadow-xl duration-150">
            <box-icon name="search" className="w-4"></box-icon>
            <p>Colima, Mex</p>
          </div>
          <div className="flex gap-1 items-center bg-white rounded-xl p-2 shadow-md cursor-pointer transform hover:scale-110 hover:shadow-xl duration-150">
            <box-icon name="search" className="w-4"></box-icon>
            <p>Barbero</p>
          </div>
        </div>
      </div>
      <div className="px-7 py-4 flex flex-col items-center gap-4">
        <p className="text-3xl">Principales empresas</p>
        <div className="flex my-2 gap-3 flex-wrap justify-center">
          <div className="w-20 h-20 bg-white rounded-xl flex item-center shadow-md transform hover:scale-125 hover:shadow-xl duration-150">
            <img src={CocaCola} alt="Empresa" />
          </div>
          <div className="w-20 h-20 bg-white rounded-xl flex item-center shadow-md transform hover:scale-125 hover:shadow-xl duration-150">
            <img src={SpaceX} alt="Empresa" />
          </div>
          <div className="w-20 h-20 bg-white rounded-xl flex item-center shadow-md transform hover:scale-125 hover:shadow-xl duration-150">
            <img src={KFC} alt="Empresa" />
          </div>
          <div className="w-20 h-20 bg-white rounded-xl flex item-center shadow-md transform hover:scale-125 hover:shadow-xl duration-150">
            <img src={Netflix} alt="Empresa" />
          </div>
          <div className="w-20 h-20 bg-white rounded-xl flex item-center shadow-md transform hover:scale-125 hover:shadow-xl duration-150">
            <img src={BBVA} alt="Empresa" />
          </div>
          <div className="w-20 h-20 bg-white rounded-xl flex item-center shadow-md transform hover:scale-125 hover:shadow-xl duration-150">
            <img src={MercadoLibre} alt="Empresa" />
          </div>
          <div className="w-20 h-20 bg-white rounded-xl flex item-center shadow-md transform hover:scale-125 hover:shadow-xl duration-150">
            <img src={AeroMexico} alt="Empresa" />
          </div>
          <div className="w-20 h-20 bg-white rounded-xl flex item-center shadow-md transform hover:scale-125 hover:shadow-xl duration-150">
            <img src={Apple} alt="Empresa" />
          </div>
          <div className="w-20 h-20 bg-white rounded-xl flex item-center shadow-md transform hover:scale-125 hover:shadow-xl duration-150">
            <img src={Bimbo} alt="Empresa" />
          </div>
        </div>
      </div>
      <div className="p-7 flex flex-col items-center gap-4">
        <p className="text-3xl">Trabajos por sector</p>
        <div className="flex flex-wrap md:gap-3">
          <div className="bg-white rounded-xl shadow-md p-5 w-1/2 md:w-40 transform hover:scale-110 hover:shadow-xl duration-150">
            <p>Tecnologías de la información</p>
            <p className="mt-5 text-3xl font-bold">10,010</p>
            <p className="text-sm">Vacantes disponibles</p>
          </div>
          <div className="bg-white rounded-xl shadow-md p-5 w-1/2 md:w-40 transform hover:scale-110 hover:shadow-xl duration-150">
            <p>Ventas</p>
            <p className="mt-5 text-3xl font-bold">2,500</p>
            <p className="text-sm">Vacantes disponibles</p>
          </div>
          <div className="bg-white rounded-xl shadow-md p-5 w-1/2 md:w-40 transform hover:scale-110 hover:shadow-xl duration-150">
            <p>Administracion</p>
            <p className="mt-5 text-3xl font-bold">7,942</p>
            <p className="text-sm">Vacantes disponibles</p>
          </div>
          <div className="bg-white rounded-xl shadow-md p-5 w-1/2 md:w-40 transform hover:scale-110 hover:shadow-xl duration-150">
            <p>Finanzas</p>
            <p className="mt-5 text-3xl font-bold">6,251</p>
            <p className="text-sm">Vacantes disponibles</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
