// Dependencies
import { Link } from "react-router-dom";

const Trabajo = ({ ofert }) => {
  return (
    <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-between py-4 px-2 border-b border-blue-700">
      <p className="w-full md:w-7/12">
        {ofert.jobTitle}
        <span className="text-sm text-gray-500 ml-2">{ofert.jobType}</span>
      </p>
      <div className="w-full md:w-3/12 flex items-center gap-2">
        <box-icon name="wifi"></box-icon>
        <p>{ofert.modality}</p>
      </div>
      <Link
        to={`/oferta/${ofert._id}`}
        className="w-full md:w-2/12 text-center text-white bg-blue-700 py-1 rounded-md"
      >
        Postularse
      </Link>
    </div>
  );
};

export default Trabajo;
