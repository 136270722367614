// Dependencies
const TextArea = (props) => {
  return (
    <div className={`${props.md ? "w-2/4" : "w-full"}`}>
      <label
        className={`block text-gray-700 text-sm font-bold mb-2 ${
          props.error && "text-red-500"
        }`}
        htmlFor={props.label}
      >
        {props.label}
        {props.required && <span className="text-red-500"> *</span>}
      </label>
      <textarea
        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none ${
          props.error && "border-red-500"
        }`}
        id={props.label}
        type={props.type}
        {...props.register}
        rows="5"
      />
      {props.error && (
        <p className="text-sm text-red-500 p-1">{props.error.message}</p>
      )}
    </div>
  );
};

export default TextArea;
