// Assets
import { Link } from "react-router-dom";

const MyEnterprisesComponent = (props) => {
  return (
    <div className="pt-24 pb-4 bg-gray-100 flex justify-center items-center">
      <div className="bg-white p-7 rounded-xl shadow-xl w-3/4">
        <h2 className="text-center text-3xl">Mis empresas</h2>
        <div className="p-7 w-full flex flex-wrap gap-5 justify-center">
          {props.enterprises.map((enterprise) => (
            <Link
              key={enterprise._id}
              to={`/enterprise/${enterprise.slug}`}
              className="w-5/12 p-2 flex flex-col gap-4 rounded-lg shadow-lg"
            >
              <img
                src={enterprise.logo}
                alt={enterprise.name}
                className="h-20"
              />
              <p className="text-center text-xl">{enterprise.name}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyEnterprisesComponent;
