// Dependencies
import { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
// Actions
import * as authActions from "../../actions/authActions";
// Components
import Child from "../../components/Perfil";
// Services
import UserServices from "../../services/Users";

const userServices = new UserServices();

class Perfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalExperienceOpen: false,
      isModalStudiesOpen: false,
      isModalCertificadosOpen: false,
      isModalConocimientosOpen: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  toggleModalExperience = () => {
    this.setState({ isModalExperienceOpen: !this.state.isModalExperienceOpen });
  };

  toggleModalStudies = () => {
    this.setState({ isModalStudiesOpen: !this.state.isModalStudiesOpen });
  };

  toggleModalCertificados = () => {
    this.setState({
      isModalCertificadosOpen: !this.state.isModalCertificadosOpen,
    });
  };

  toggleModalConocimientos = () => {
    this.setState({
      isModalConocimientosOpen: !this.state.isModalConocimientosOpen,
    });
  };

  addExperience = (datos) => {
    userServices
      .createExperience(datos)
      .then((res) => {
        this.props.setLogin();
        swal("Exito!", "Experiencia agregada", "success").then(() => {
          this.toggleModalExperience();
        });
      })
      .catch((err) => {
        swal("Error", "Error al agregar experiencia", "error");
      });
  };

  removeExperience = (id) => {
    swal({
      title: "Advertencia!",
      text: "Deseas eliminar este elemento?",
      icon: "warning",
      buttons: {
        cancel: "Cancelar",
        default: "Eliminar",
      },
    }).then((eliminar) => {
      if (eliminar) {
        userServices
          .deleteExperience(id)
          .then(() => {
            this.props.setLogin();
            swal("Exito!", "Experiencia Eliminada", "success");
          })
          .catch(() => {
            swal("Error!", "Error al eliminar experiencia", "error");
          });
      }
    });
  };

  addStudy = (datos) => {
    userServices
      .createStudy(datos)
      .then(() => {
        this.props.setLogin();
        swal("Exito!", "Experiencia agregada", "success").then(() => {
          this.toggleModalStudies();
        });
      })
      .catch(() => {
        swal("Error", "Error al agregar experiencia", "error");
      });
  };

  removeStudy = (id) => {
    swal({
      title: "Advertencia!",
      text: "Deseas eliminar este elemento?",
      icon: "warning",
      buttons: {
        cancel: "Cancelar",
        default: "Eliminar",
      },
    }).then((eliminar) => {
      if (eliminar) {
        userServices
          .deleteStudy(id)
          .then(() => {
            this.props.setLogin();
            swal("Exito!", "Experiencia Eliminada", "success");
          })
          .catch(() => {
            swal("Error!", "Error al eliminar experiencia", "error");
          });
      }
    });
  };

  addCertificado = (datos) => {
    userServices
      .createCertificado(datos)
      .then(() => {
        this.props.setLogin();
        swal("Exito!", "Experiencia agregada", "success").then(() => {
          this.toggleModalCertificados();
        });
      })
      .catch(() => {
        swal("Error", "Error al agregar experiencia", "error");
      });
  };

  removeCertificado = (id) => {
    swal({
      title: "Advertencia!",
      text: "Deseas eliminar este elemento?",
      icon: "warning",
      buttons: {
        cancel: "Cancelar",
        default: "Eliminar",
      },
    }).then((eliminar) => {
      if (eliminar) {
        userServices
          .deleteCertificado(id)
          .then(() => {
            this.props.setLogin();
            swal("Exito!", "Experiencia Eliminada", "success");
          })
          .catch(() => {
            swal("Error!", "Error al eliminar experiencia", "error");
          });
      }
    });
  };

  addConocimiento = (datos) => {
    userServices
      .createConocimiento(datos)
      .then(() => {
        this.props.setLogin();
        swal("Exito!", "Experiencia agregada", "success").then(() => {
          // this.toggleModalConocimientos();
        });
      })
      .catch(() => {
        swal("Error", "Error al agregar experiencia", "error");
      });
  };

  removeConocimiento = (id) => {
    swal({
      title: "Advertencia!",
      text: "Deseas eliminar este elemento?",
      icon: "warning",
      buttons: {
        cancel: "Cancelar",
        default: "Eliminar",
      },
    }).then((eliminar) => {
      if (eliminar) {
        userServices
          .deleteConocimiento(id)
          .then(() => {
            this.props.setLogin();
            swal("Exito!", "Experiencia Eliminada", "success");
          })
          .catch(() => {
            swal("Error!", "Error al eliminar experiencia", "error");
          });
      }
    });
  };

  datosUsuario = () => {
    if (!this.props.authReducer.user) return {};
    return { ...this.props.authReducer.user };
  };

  render() {
    return (
      <Child
        datosUsuario={this.datosUsuario}
        addExperience={this.addExperience}
        removeExperience={this.removeExperience}
        isModalExperienceOpen={this.state.isModalExperienceOpen}
        toggleModalExperience={this.toggleModalExperience}
        isModalStudiesOpen={this.state.isModalStudiesOpen}
        toggleModalStudies={this.toggleModalStudies}
        addStudy={this.addStudy}
        removeStudy={this.removeStudy}
        isModalCertificadosOpen={this.state.isModalCertificadosOpen}
        toggleModalCertificados={this.toggleModalCertificados}
        addCertificado={this.addCertificado}
        removeCertificado={this.removeCertificado}
        isModalConocimientosOpen={this.state.isModalConocimientosOpen}
        toggleModalConocimientos={this.toggleModalConocimientos}
        addConocimiento={this.addConocimiento}
        removeConocimiento={this.removeConocimiento}
      />
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  return {
    authReducer,
  };
};

export default connect(mapStateToProps, authActions)(Perfil);
