// Dependencies
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// Componens
import Modal from "../Global/Modal";
import ConocimientoList from "./ConocimientoList";
import Input from "../Global/Input";

const schema = yup
  .object({
    conocimiento: yup.string().required("Campo Obligatorio").trim(),
  })
  .required();

const ModalConocimientos = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const agregar = (datos) => {
    props.addConocimiento(datos);
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <p className="text-center text-3xl mb-5">Conocimientos</p>
      <ConocimientoList
        conocimientos={props.conocimientos}
        edit
        removeConocimiento={props.removeConocimiento}
      />
      <form
        onSubmit={handleSubmit(agregar)}
        noValidate
        className="flex flex-col items-center gap-2 mt-4"
      >
        <Input
          type="text"
          label="Agregar Conocimiento"
          register={register("conocimiento")}
          required
          error={errors.conocimiento}
          md
        />
        <div className="w-2/4">
          <button
            type="submit"
            className="bg-blue-700 text-white py-2 px-4 rounded-xl shodow-xl w-full"
          >
            Agregar
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalConocimientos;
