// Dependencies
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// Components
import ModalTiny from "../Global/ModalTiny";
import Input from "../Global/Input";

const schema = yup
  .object({
    title: yup.string().required("Campo Obligatorio").trim(),
    institution: yup.string().required("Campo Obligatorio").trim(),
    expeditionDate: yup
      .date()
      .required("Campo Obligatorio")
      .typeError("Fecha Incorrecta"),
  })
  .required();

const ModalCertificados = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const addCertificado = (datos) => {
    props.addCertificado(datos);
  };

  return (
    <ModalTiny isOpen={props.isOpen} onClose={props.onClose}>
      <div className="my-2">
        <p className="text-center text-3xl mb-5">Agregar Certificado</p>
        <form
          onSubmit={handleSubmit(addCertificado)}
          className="flex flex-col gap-3"
        >
          <Input
            error={errors.title}
            label="Titulo"
            type="text"
            register={register("title")}
            required
          />
          <Input
            error={errors.institution}
            label="Institución"
            type="text"
            register={register("institution")}
            required
          />
          <Input
            error={errors.expeditionDate}
            label="Expedicion"
            type="date"
            register={register("expeditionDate")}
            required
          />
          <button
            type="submit"
            className="py-2 px-4 rounded-xl bg-blue-700 text-white shadow-md transform hover:scale-110 hover:shadow-xl duration-300"
          >
            Guardar
          </button>
        </form>
      </div>
    </ModalTiny>
  );
};

export default ModalCertificados;
