// Dependencies
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import swal from "sweetalert";
// Components
import Input from "../../Global/Input";
import TextArea from "../../Global/TextArea";

const schema = yup
  .object({
    name: yup.string().required("Campo Obligatorio").trim(),
    description: yup.string().required("Campo Obligatorio").trim(),
    about: yup.string(),
    benefits: yup.string(),
  })
  .required();

const EnterpriseRegistrationComponent = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [logo, setLogo] = useState(null);
  const [mostrarLogo, setMostrarLogo] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [mostrarCoverImage, setMostrarCoverImage] = useState(null);

  const registrar = (datos) => {
    let errorMesaje = "Por favor ingresa ";
    let haveError = false;
    if (!logo) {
      haveError = true;
      errorMesaje += "el logo de empresa ";
    }
    if (!coverImage) {
      if (haveError) {
        errorMesaje += " y la imagen de portada";
      } else {
        haveError = true;
        errorMesaje += "la imagen de portada";
      }
    }
    if (haveError) {
      return swal("Campos imcompletos", errorMesaje, "warning");
    }
    props.addEnterprise({ ...datos, logo, coverImage });
  };

  const validateImage = (file) => {
    let valido =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/svg+xml";
    return valido;
  };

  const changeLogo = (e) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    let valido = validateImage(file);
    if (valido) {
      setMostrarLogo(URL.createObjectURL(file));
      setLogo(file);
    } else {
      swal(
        "Archivo no valido",
        "Por favor ingresa una un formato en jpg, jpge, png o svg",
        "warning"
      );
    }
  };

  const removeLogo = () => {
    setLogo(null);
    setMostrarLogo(null);
  };

  const changeCoverImage = (e) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    let valido = validateImage(file);
    if (valido) {
      setMostrarCoverImage(URL.createObjectURL(file));
      setCoverImage(file);
    } else {
      swal(
        "Archivo no valido",
        "Por favor ingresa una un formato en jpg, jpge, png o svg",
        "warning"
      );
    }
  };

  const removeCoverImage = () => {
    setCoverImage(null);
    setMostrarCoverImage(null);
  };

  return (
    <div className="bg-gray-100 pt-24 pb-5 flex justify-center">
      <div className="bg-white w-3/4 rounded-xl shadow-xl p-7">
        <h2 className="text-center text-3xl">Registra tu empresa</h2>
        <form
          className="my-10 flex flex-col gap-2"
          onSubmit={handleSubmit(registrar)}
        >
          <div className="flex flex-col items-center">
            <p className="text-gray-700 text-sm font-bold block w-full">
              Logo <span className="text-red-500">*</span>
            </p>
            {!logo ? (
              <>
                <label className="p-7 cursor-pointer" htmlFor="logo">
                  <box-icon
                    type="solid"
                    name="camera-plus"
                    size="lg"
                  ></box-icon>
                </label>
                <input type="file" hidden id="logo" onChange={changeLogo} />
              </>
            ) : (
              <div className="flex flex-col items-center">
                <img src={mostrarLogo} alt="Logo de empresa" className="h-32" />
                <button
                  type="button"
                  className="underline text-red-500"
                  onClick={removeLogo}
                >
                  Eliminar
                </button>
              </div>
            )}
          </div>
          <Input
            label="Nombre"
            type="text"
            register={register("name")}
            error={errors.name}
            md
            required
          />
          <TextArea
            label="Description"
            type="text"
            register={register("description")}
            error={errors.description}
            required
          />
          <div className="flex flex-col items-center">
            <p className="text-gray-700 text-sm font-bold block w-full">
              Imagen de portada <span className="text-red-500">*</span>
            </p>
            {!coverImage ? (
              <>
                <label className="p-7 cursor-pointer" htmlFor="logo">
                  <box-icon
                    type="solid"
                    name="camera-plus"
                    size="lg"
                  ></box-icon>
                </label>
                <input
                  type="file"
                  hidden
                  id="logo"
                  onChange={changeCoverImage}
                />
              </>
            ) : (
              <div className="flex flex-col items-center">
                <img
                  src={mostrarCoverImage}
                  alt="Logo de empresa"
                  className="h-32"
                />
                <button
                  type="button"
                  className="underline text-red-500"
                  onClick={removeCoverImage}
                >
                  Eliminar
                </button>
              </div>
            )}
          </div>
          <TextArea
            label="Sobre la empresa"
            type="text"
            register={register("about")}
            error={errors.about}
          />
          <TextArea
            label="Beneficios"
            type="text"
            register={register("benefits")}
            error={errors.benefits}
          />
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-700 text-white py-2 px-4 rounded-md shadow-xl text-lg"
            >
              Registrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnterpriseRegistrationComponent;
