// Dependencies
import Axios from "axios";

const base_url = process.env.REACT_APP_SERVER_URL;

class EnterprisesServices {
  create = (enterprise) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(enterprise).forEach((key) =>
        formData.append(key, enterprise[key])
      );
      Axios.post(`${base_url}/enterprises`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  getEnterprise = (slug) =>
    new Promise((resolve, reject) => {
      Axios.get(`${base_url}/enterprises/${slug}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });

  getAll = () =>
    new Promise((resolve, reject) => {
      Axios.get(`${base_url}/enterprises/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });
  getOferts = (slug) =>
    new Promise((resolve, reject) => {
      Axios.get(`${base_url}/enterprises/${slug}/oferts`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          let errorMessage;
          try {
            errorMessage = err.response.data;
          } catch (error) {
            errorMessage = "Error al conectarse al servidor";
          }
          reject(errorMessage);
        });
    });
}

export default EnterprisesServices;
