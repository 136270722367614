// Dependencies
import { Component } from "react";
import swal from "sweetalert";
// components
import NewOfertComponent from "../../components/Oferta/New";
// Services
import OfertasServices from "../../services/Ofertas";

const ofertasServices = new OfertasServices();

class NewOfert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addOfert = (datos) => {
    const ofert = {...datos, _enterprise: this.props.match.params.id }
    ofertasServices
      .addOfert(ofert)
      .then((res) => {
        swal("Acción Exitosa!", "Oferta creada", "success");
      })
      .catch((err) => {
        swal("Oooops!", err.message || err, "error");
      });
  };

  render() {
    return <NewOfertComponent addOfert={this.addOfert} />;
  }
}

export default NewOfert;
