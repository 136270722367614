const Footer = () => {
    return (
        <div className="static bottom-0 bg-gray-100 w-full border-t p-5 flex flex-col gap-4">
            <ul className="flex gap-4">
                <li>Terminos y condiciones</li>
                <li>Aviso de privacidad</li>
                <li>Mapa de sitio</li>
            </ul>
            <p>
            Jobber © 2021 Derechos reservados.
            </p>
        </div>
    )
}

export default Footer;