// Dependencies
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// Componentes
import Input from "../Global/Input";
import TextArea from "../Global/TextArea";
import Select from "../Global/Select";

const schema = yup
  .object({
    jobTitle: yup.string().required("Campo Obligatorio").trim(),
    modality: yup.string().required("Campo Obligatorio").trim(),
    jobType: yup.string().required("Campo Obligatorio").trim(),
    sector: yup.string().required("Campo Obligatorio").trim(),
    description: yup.string().required("Campo Obligatorio").trim(),
    jobFunctions: yup.string().trim(),
    jobRequirements: yup.string().trim(),
    desirableJobRequirements: yup.string().trim(),
  })
  .required();

const NewOfertComponent = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const newOfert = (datos) => {
    props.addOfert(datos)
  };

  return (
    <div className="bg-gray-100 pt-24 pb-4 flex justify-center items-center">
      <div className="w-11/12 md:w-3/4 bg-white shadow-xl rounded-xl p-7">
        <h2 className="text-4xl text-center">Nueva Oferta</h2>
        <form
          onSubmit={handleSubmit(newOfert)}
          className="my-10 flex flex-col gap-2"
        >
          <div className="flex flex-col md:flex-row gap-2">
            <Input
              label="Nombre del puesto"
              required
              md
              register={register("jobTitle")}
              error={errors.jobTitle}
            />
            <Select
              label="Modalidad"
              required
              md
              register={register("modality")}
              error={errors.modality}
              values={["Presencial", "Hibrido", "Remoto"]}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <Select
              label="Tipo de Empleo"
              required
              md
              register={register("jobType")}
              error={errors.jobType}
              values={[
                "Jornada Completa",
                "Media Jornada",
                "Interno",
                "Becario",
              ]}
            />
            <Select
              label="Sector"
              required
              md
              register={register("sector")}
              error={errors.sector}
              values={[
                "Tecnologias de la informacion",
                "Ventas",
                "Administracion",
                "Finanzas",
              ]}
            />
          </div>
          <TextArea
            label="Descripcion"
            required
            register={register("description")}
            error={errors.description}
          />
          <TextArea
            label="Funciones del cargo"
            register={register("jobFunctions")}
            error={errors.jobFunctions}
          />
          <TextArea
            label="Requerimientos del cargo"
            register={register("jobRequirements")}
            error={errors.jobFunctions}
          />
          <TextArea
            label="Requerimientos del cargo (Deseables)"
            register={register("desirableJobRequirements")}
            error={errors.jobFunctions}
          />
          <div className="flex justify-center">
            <button type="submit" className="w-full md:w-auto bg-blue-700 text-white px-4 py-2 rounded-lg text-xl">
              Agregar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewOfertComponent;
