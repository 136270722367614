const Conocimiento = (props) => {
  const { conocimiento, _id } = props.datos;

  return (
    <div className="bg-gray-100 px-2 py-2 rounded-xl text-sm flex gap-2">
      {props.edit && (
        <box-icon
          name="x"
          onClick={() => props.remove(_id)}
        ></box-icon>
      )}
      <p className="">{conocimiento}</p>
    </div>
  );
};

export default Conocimiento;
