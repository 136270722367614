// Dependencies
import { useState } from "react";
import { Link } from "react-router-dom";

const Search = () => {
  const [puesto, setPuesto] = useState("");
  const [ubicacion, setUbicacion] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="w-full flex justify-center bg-blue-700 pt-10">
      <div className="w-4/5 py-20 flex flex-col gap-8">
        <p className="text-lg text-white text-4xl font-bold">Buscar</p>
        <form
          className="w-full flex flex-col md:flex-row gap-5"
          onSubmit={handleSubmit}
        >
          <div className="flex w-full lg:w-2/5 bg-white rounded-lg items-center">
            <box-icon name="search" className="w-1/12"></box-icon>
            <input
              type="text"
              placeholder="Puesto Laboral"
              className="w-11/12 bg-transparent outline-none text-xl py-2"
              onChange={({ target }) => setPuesto(target.value)}
            />
          </div>
          <div className="flex w-full lg:w-2/5 bg-white rounded-lg items-center">
            <box-icon name="map" className="w-1/12"></box-icon>
            <input
              type="text"
              placeholder="Ubicacion"
              className="w-11/12 bg-transparent outline-none text-xl py-2"
              onChange={({ target }) => setUbicacion(target.value)}
            />
          </div>
          <Link to="/busqueda" className="w-full lg:w-1/5 text-white rounded-lg py-2 bg-red-500 transform hover:scale-110 hover:shadow-xl duration-150 flex justify-center">
            <button >
              Buscar Empleo
            </button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Search;
