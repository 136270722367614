// Dependencies
import { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
// Actions
import * as authActions from "../../../actions/authActions";
// Services
import UserServices from "../../../services/Users";
// Components
import Child from "../../../components/Perfil/Editar";

class EditarPerfil extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  updateUser = async (datos) => {
    await new UserServices()
      .updateUser(this.props.authReducer.user._id, datos)
      .then((res) => {
        swal("Exito!", "Perfil Actualizado", "success").then(() => {
          this.props.setLogin();
          this.props.history.push("/perfil");
        });
      })
      .catch((err) =>
        swal(
          "Ooooops!",
          "Ha Ocurrido un error al actualizar el perfil",
          "error"
        )
      );
  };

  render() {
    return (
      <Child
        updateUser={this.updateUser}
        datosUsuario={this.props.authReducer.user}
      />
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  return {
    authReducer,
  };
};

export default connect(mapStateToProps, authActions)(EditarPerfil);
