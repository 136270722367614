import Moment from "moment";

const Certificado = (props) => {
  const { title, institution, expeditionDate, _id } = props.datos;

  return (
    <div className="">
      <div className="flex justify-between">
        <p className="font-bold text-lg">{title}</p>
        <div className="flex gap-3">
          <box-icon
            name="trash"
            onClick={() => props.removeCertificado(_id)}
          ></box-icon>
        </div>
      </div>
      <p className="text-blue-600">{institution}</p>
      <p>Expedición: {Moment(expeditionDate).format("MMM YYYY")}</p>
    </div>
  );
};

export default Certificado;
