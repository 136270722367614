/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import { useEffect } from "react";
import { Link } from "react-router-dom";
// Components
import Trabajo from "./Trabajo";
// Assets
import styles from "../../styles/Enterprise/index.module.scss";

const EnterpriseComponent = (props) => {
  useEffect(() => {
    if (props.enterprise.coverImage) {
      setCoverImage();
    }
  }, []);

  const setCoverImage = () => {
    const div = document.getElementsByClassName(styles.image)[0];
    div.style.backgroundImage = `url(${props.enterprise.coverImage})`;
  };

  return (
    <div className="bg-gray-100 pt-24 pb-3 flex flex-col justify-center items-center gap-2">
      <div className="w-11/12 md:w-3/4 bg-white shadow-xl rounded-xl p-10">
        <div className="flex flex-col-reverse md:flex-row gap-10">
          <div className="w-full md:w-2/4 flex flex-col gap-0">
            <h2
              className={`text-bold ${styles.title} text-5xl lg:text-6xl text-center md:text-left`}
            >
              Trabaja en {props.enterprise.name}
            </h2>
            <p className="text-xl whitespace-pre-wrap">
              {props.enterprise.description}
            </p>
          </div>
          <div className="w-full md:w-2/4 flex justify-center">
            <div className={`${styles.image} shadow-xl rounded-lg`}></div>
          </div>
        </div>
      </div>
      <div className="w-11/12 md:w-3/4 bg-white shadow-xl rounded-xl p-10 flex justify-center">
        <div className="w-full md:w-3/4 flex flex-col items-center gap-10">
          <div className="flex flex-col gap-2">
            <p className="text-3xl font-bold">Sobre Nosotros</p>
            <p className="text-lg whitespace-pre-wrap">
              {props.enterprise.about}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-3xl font-bold">Beneficios</p>
            <p className="text-lg whitespace-pre-wrap">
              {props.enterprise.benefits}
            </p>
          </div>
        </div>
      </div>
      <div className="w-11/12 md:w-3/4 bg-white shadow-2xl rounded-xl p-10 flex justify-center">
        <div className="flex flex-col w-full md:w-3/4 gap-5 py-10">
          <Link to={`/oferta/new/${props.enterprise._id}`}>
            Registrar oferta
          </Link>
          <p className="text-3xl font-bold">Ofertas Laborales</p>
          <div className="flex flex-col gap-4">
            {props.oferts.map((ofert) => (
              <Trabajo key={ofert._id} ofert={ofert} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseComponent;
