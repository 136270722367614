// Dependencies
import { Component } from "react";
import { connect } from "react-redux";
// Components
import Child from "../../components/Dashboard";

class Dashboard extends Component {
  datosUsuario = () => {
    if (!this.props.authReducer.user) return {};
    return { ...this.props.authReducer.user };
  };

  componentDidMount = () => {
    if (!this.props.authReducer.login) {
      this.props.history.push("signin");
    }
  };

  render() {
    return <Child datosUsuario={this.datosUsuario} />;
  }
}

const mapStateToProps = ({ authReducer }) => ({
  authReducer,
});

export default connect(mapStateToProps)(Dashboard);
