// Dependencies
import { Component } from 'react';
// Components
import Child from "../../components/LandingPage"

class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <Child/>
         );
    }
}
 
export default LandingPage;